<template>
  <form id="frm_action">
  <div class="p-formgrid">
        <div class="p-card-field">
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Ngày thanh toán<span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col vd-select">
              <Calendar dateFormat="yy-mm-dd" v-model="date_add" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" :showIcon="true" class="p-col-width" placeholder="Ngày thanh toán" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">User<span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col vd-select">
              <v-select id= "a1" :filterable="false" :clearable="false" placeholder="Chọn user" v-model="selUser" :options="listUser" label="fullname" @search="fetchOptionsUser" @input="UpdateDateExpiration" class="p-col-width">
                <span slot="no-options" @click="$refs.select.open = false">
                  Không có dữ liệu
                </span>
              </v-select>
              <input class="input_tmp_validator" id="validator_list_user">
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Gói kích hoạt <span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col vd-select">
              <v-select id= "a2" :filterable="false" :clearable="false" placeholder="Chọn gói" v-model="selPackages" :options="listPackages" label="name" @search="fetchOptionsPackages" @input="UpdateDateExpiration" class="p-col-width">
                <span slot="no-options" @click="$refs.select.open = false">
                  Không có dữ liệu
                </span>
              </v-select>
              <input class="input_tmp_validator" id="validator_list_packages">
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed in-d cus-label-title">Ngày hết hạn hiện tại</label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText disabled v-model="expiration_date_old" id="expiration_date_old" type="text" class="p-col-width" placeholder="" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed in-d cus-label-title" style="width:auto">Ngày hết hạn sau khi kích hoạt (dự kiến)</label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText disabled v-model="expiration_date_new" id="expiration_date_new" type="text" class="p-col-width" placeholder="" />
            </div>
          </div>
          
        </div>
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
        />
      </template>
    </Toolbar>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import ApiRepository from "@/core/ApiRepository";
import jq from 'jquery';
import moment from "moment-timezone";
export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      ordinal : 1,
      validationErrors: {},
      formMode: this.mode,
      saving:false,
      url : '',
      listUserAll : [],
      listUser : [],
      selUser : null,
      listPackagesAll : [],
      listPackages : [],
      selPackages : null,
      active : true,
      expiration_date_old : null,
      expiration_date_new : null,
      date_add : new Date(),
    }
  },
  async mounted() {
    var year = new Date().getFullYear()
    var yearRange = '2024:'+(year+200);
    this.yearRange = yearRange+'';
    await this.getListUser()
    await this.getListPackages()
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model",this.model)
    }
  },
  methods: {
    async UpdateDateExpiration(){
      // console.log("check selUser",this.selUser)
      // console.log("check selPackages",this.selPackages)
      if(this.selUser){
        if(this.selUser.expiration_date){
          this.expiration_date_old = moment.tz(this.selUser.expiration_date, "Asia/Ho_Chi_Minh").format("HH:mm:ss DD-MM-YYYY");
        }else{
          this.expiration_date_old = null
        }
      }else {
        this.expiration_date_old = null
      }
      if(this.selUser && this.selPackages){
        if(this.selPackages.is_forever == true){
          this.expiration_date_new = 'Vĩnh viễn'
        }else{
          this.expiration_date_new = this.CalAddDate(this.selPackages.value,this.selUser.expiration_date)
        }
        
      }
      // updateShowInfomation()
    },
    async fetchOptionsPackages(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        let {data} = await this.$apollo.query({
          query: DataServices.getList('packages',{"fields" : "id name value is_forever"}),
          variables: {
            where_key: {
              // deleted: {_eq: false},
              plain_name:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            },
            orderBy:{value: 'asc'}
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        this.listPackages = list
      }else{
        this.listPackages = this.listPackagesAll
      }
    },
    async getListPackages(){
      let {data} = await this.$apollo.query({
        query: DataServices.getList('packages',{"fields" : "id name value is_forever"}),
        variables: {
          where_key: {
            // deleted: {_eq: false},
          },
          orderBy:{value: 'asc'}
        },
        fetchPolicy: "network-only"
      })

      var list = data[Object.keys(data)];
      // var list_chapter = [];
      this.listPackages = list
      this.listPackagesAll = list
      // this.selChapter = null;
    },
    async fetchOptionsUser(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : "id fullname expiration_date"}),
          variables: {
            where_key: {
              // name: {_is_null : false},
              deleted: {_eq: false},
              is_forever : {_eq: false },
              plain_fullname:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            },
            orderBy:{created_at: 'desc'}
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        this.listUser = list
      }else{
        this.listUser = this.listUserAll
      }
    },
    async getListUser(){
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : "id fullname expiration_date"}),
        variables: {
          where_key: {
            // name : {_is_null : false},
            deleted: {_eq: false},
            is_forever : {_eq: false },
          },
          orderBy:{created_at: 'desc'}
        },
        fetchPolicy: "network-only"
      })

      var list = data[Object.keys(data)];
      // var list_courses = [];
      this.listUser = list
      this.listUserAll = list
      // this.selCourses = null;
    },
    CalAddDate(number,date=null) {
      var currentDate = date ? moment.tz(date,'UTC') : moment.tz('UTC') ;
      var add = number*1;
      var futureDate = moment.tz(currentDate,'UTC').add(add, 'M');
      return moment.tz(futureDate,'Asia/Ho_Chi_Minh').format('HH:mm:ss DD-MM-YYYY');
    },
    backToList() {
      this.$emit('back');
    },
    handleDataCkditor: function(e,key) {
      var dataContent = (e.length) ? e[0]:'';
      if (key == 'content'){
        this.content = dataContent;
      }else if (key == 'content_en'){
        this.content_en = dataContent;
      }
    },
    CallPhoneSel(){
      if(this.selUser !== undefined && this.selUser !== null){
        this.phone = this.selUser.phone
      }
    },
    async validateData() {
      let user_add = document.getElementById("validator_list_user");
      if (this.selUser == undefined || this.selUser == null || this.selUser == {}){
        user_add.setCustomValidity("Vui lòng chọn user!");
      }else {
        user_add.setCustomValidity("");
      }
      let package_add = document.getElementById("validator_list_packages");
      if (this.selPackages == undefined || this.selPackages == null || this.selPackages == {}){
        package_add.setCustomValidity("Vui lòng chọn gói!");
      }else {
        package_add.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async getTimeVideo(){
      var data = {
        url : this.url
      }
      var duration = null 
      if(this.url){
        await ApiRepository.post(this.$constants.API.GET_INFO_YOUTUBE, data, {}).then((response) => {
          if(response.data && response.data.data){
            // console.log("ressssssssssss===",response.data.data)
            if(response.data.data.duration >= 0){
              duration = response.data.data.duration
            }
          }
        })
      }
      // console.log("duration",duration)
      return duration
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      
      var params = {
        user : this.selUser,
        packages : this.selPackages,
        date_add : this.date_add,
      }
      console.log("params",params)
      this.$emit('save', params);
      // this.$commonFuction.is_loading(false);
      
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        if (files.length > 1) {
          event.target.value = "";
          alert("Chỉ được upload 1 file");
          return;
        }
        let acceptTags = [
          "docx", "doc", "xlsx", "pptx", "pdf","xls",
          "DOCX", "DOC", "XLSX", "PPTX", "PDF","XLS",
        ];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            event.target.value = "";
            alert(
              "Vui lòng upload file có định dạng: docx, doc, xlsx, pptx, pdf"
            );
            return;
          }
        }
        var arrTemp = [],
          arrFiles = [];
        for (let file of files) {
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }

        if (arrFiles.length > 0) {
          event.target.value = "";
          alert("File của bạn đã quá dung lượng 100MB");
          return;
        } else {
          this.files = []
          this.files = this.files.concat.apply(this.files, arrTemp);
        }
        
      }
      event.target.value = "";
      return;
    },
    onFileClear(index) {
      let file = this.files[index];
      console.log("this.files",this.files)
      this.files.splice(index, 1);
      if (file.file === "" && file.id) this.filesDeletes.push(file.id);
    },
    async onFileDownload(index) {
      let file = this.files[index];
      let files = [{download: file?.url, filename: file?.name}]
      await this.download_files(files);
      // window.open(file.objectURL);
    },
    download_files(files) {
      function download_next(i) {
        if (i >= files.length) {
          return;
        }
        var a = document.createElement('a');
        a.href = files[i].download;
        a.target = '_parent';
        // Use a.download if available, it prevents plugins from opening.
        if ('download' in a) {
          a.download = files[i].filename;
        }
        // Add a to the doc for click to work.
        (document.body || document.documentElement).appendChild(a);
        if (a.click) {
          a.click(); // The click method is supported by most browsers.
        } else {
          jq(a).click(); // Backup using jquery
        }
        // Delete the temporary link.
        a.parentNode.removeChild(a);
        // Download the next file with a small timeout. The timeout is necessary
        // for IE, which will otherwise only download the first file.
        setTimeout(function() {
          download_next(i + 1);
        }, 500);
      }
      // Initiate the first download.
      download_next(0);
    },
  }
}
</script>

<style lang="scss">
  .p-invalid {
    color: red;
    margin: 1px 0px 0px 0px;
  }
  .text-active{
    margin: 0px 0px 0px 8px;
  }
  .div-active-item{
    max-width: 120px!important;
    width: 50px!important;
    display: flex!important;
    align-items: flex-start!important;
  }
  .one-active{
    margin-left: 5px!important;
  }
  .vd-select{
    .vs__dropdown-toggle{
      min-height: 40px;
    }
  }
  </style>
